import React from 'react'
import { Col } from 'react-bootstrap'

const SideBar = ({ children, title, phone, email }) => (
    <Col className='sidebarBox' xs='12' sm='12' md='4' lg='4'>
        {children}
        <Col className='orange'>
            <h3>Meet the Team</h3>
            <p>With over two decades of combined legal experience in this field of law, we swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers. We routinely collaborate with a team of land development experts, inclusive of architects, engineers and planners, in an effort to obtain expedited and cost-effective outcomes for our clients.</p>
            <button className='btn btn-secondary center-block'><a href='/team/'>See All</a></button>
        </Col>
        <Col className='gray'>
            <h3>Call or Contact Us Today!</h3>
            <p><strong>{title}</strong><br />
            Tel: {phone}<br />
            Email: <a href={`mailto:${email}`}>{email}</a></p>
            <button className='btn btn-secondary center-block'><a href='/connect/'>Connect</a></button>
        </Col>
    </Col>
)

export default SideBar
