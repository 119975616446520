import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import AboutSeoImage from '../assets/images/boardroom.jpg'

class About extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query AboutMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='about'>
                    <Seo title={`About Our Practice | ${data.site.siteMetadata.title}`} description={`With over two decades of combined legal experience in this field of law, ${data.site.siteMetadata.title} swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers.`} image={AboutSeoImage} keywords={[``]} />
                    <Banner title='About Our Practice' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={AboutSeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <h4>We specialize in all aspects of real estate development including legal representation in connection with zoning and land use matters, purchase and sale of development rights, leasing, license and easement agreements, redevelopment, construction disputes, real estate negotiations and litigation, together with municipal and county board approvals, and various state agency permitting and approvals.</h4>
                                    <p>With over two decades of combined legal experience in this field of law, we swiftly and efficiently navigate through the municipal, county and state agency land development approval process faced by developers. We routinely collaborate with a team of land development experts, inclusive of architects, engineers and planners, in an effort to obtain expedited and cost-effective outcomes for our clients.</p>
                                    <p>Our clients include Fortune 300 companies, medical centers, national banking institutions, educational institutions, assisted-living facilities, auto-service stations, non-profit organizations, telecommunications carriers, high-density residential developers, national and regional developers, in all aspects of land development.</p>
                                    <p>We look forward to working with you on your next project.</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} contact={data.site.siteMetadata.contact} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default About