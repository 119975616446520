import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import bannerImg from '../assets/images/neighborhood.jpg'

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.title = this.props.title
        this.titleColor = this.props.titleColor
        this.subTitle = this.props.subTitle
        this.subTitleColor = this.props.subTitleColor
        this.image = this.props.image || bannerImg
    }
    
    render() {
        return (
            <section id='banner' style={{background: `url(${this.image}) center center no-repeat`, backgroundSize: `cover`}}>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <h1 style={{color: `${this.titleColor}`}}>{this.title}</h1>
                            <h3 style={{color: `${this.subTitleColor}`}}>{this.subTitle}</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Banner
